import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Link from '../components/common/link'
import Seo from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  max-width: 600px;
  margin: 0 auto;

  h1 {
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.contrast};
    background: ${({ theme }) => theme.brand};
  }

  @media (min-width: 768px) {
    padding: 4rem 0;
  }
`
const List = styled.ol`
  width: 100%;
  margin: 1rem 0;

  h2 {
    font-weight: 700;
  }

  a {
    color: ${({ theme }) => theme.contrast};
  }

  small {
    display: inline-block;
    color: ${({ theme }) => theme.contrast};
    background: ${({ theme }) => theme.brand};
    padding: 0.25em 0.5em;
    margin-bottom: 0.25em;
    font-size: 0.65rem;
  }
`
const Inner = styled.li`
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.contrast};
  margin: 0.5rem 0;
  width: 100%;
`
const StyledLink = styled(Link)`
  display: flex;

  > div:first-child {
    flex: 1 0 100px;
  }
`
const Content = styled.article`
  padding: 0.5em 1em;
`
const Reviews = ({ data }) => {
  const reviews = data.allMarkdownRemark.nodes

  if (reviews.length === 0) {
    return (
      <>
        <Seo title='All Reviews' />
        <p>
          Obviously Stephen broke something and you should contact Justin
          immediately to fix it.
        </p>
      </>
    )
  }

  return (
    <Container>
      <Seo title='All Reviews' />
      <h1>Reviews</h1>
      <List>
        {reviews.map((review) => {
          const title = review.frontmatter.title || review.fields.slug

          return (
            <Inner key={review.fields.slug}>
              <StyledLink to={review.fields.slug} itemProp='url'>
                <GatsbyImage
                  image={
                    review.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                  alt=''
                  style={{
                    maxWidth: '100px',
                    aspectRatio: '1',
                  }}
                />
                <Content itemScope itemType='http://schema.org/Article'>
                  <header>
                    <small>{review.frontmatter.date}</small>
                    <h2 itemProp='headline'>
                      {title} - {review.frontmatter.variant}
                    </h2>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          review.frontmatter.description || review.excerpt,
                      }}
                      itemProp='description'
                    />
                  </section>
                </Content>
              </StyledLink>
            </Inner>
          )
        })}
      </List>
    </Container>
  )
}

export default Reviews

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          variant
          brewery
          date(formatString: "MMMM DD, YYYY")
          description
          score
          featuredImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
